.tasting {
  height: 100%;
  width: 100%;

  .tasting-wrap {
    width: 100%;
  }

  button {
    margin: 0 0 1rem 0;
    width: 100%;

    span {
      display: block;
    }

    &.save-answer {
      border: none;
      border-radius: 0;
      bottom: 0;
      font-size: 0.9rem;
      left: 0;
      margin: 0;
      padding: 0.8rem 0;
      position: fixed;
    }
  }

  p.taste-glass, p.taste-num {
    font-size: 0.8rem;
    height: 18px;
    line-height: 18px;
    position: absolute;
    right: 0.4rem;
    top: 10px;

    &.taste-glass {
      top: 28px;
    }
  }
}
