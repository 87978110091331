.leaderboard {
  .lb--user { 
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  
    &:nth-child(even) {
      background-color: rgba(#000, 0.3);
    }
  
    &.me, &.me:nth-child(even) {
      background-color: #000;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem 0;
  }
}
