body {
  margin: 0;
  padding: 0;
}

html, body, #root, .app {
  height: 100%;
  width: 100%;
}

.ctr {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.fix--full {
  background-color: #222;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 100;
}

.upr {
  text-transform: uppercase;
}

* {
  outline: 0 !important;
}
