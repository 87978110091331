.summary {
	.small-lb {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: flex;
				justify-content: space-between;
				padding: 0.5rem 2rem;

				&:nth-child(even) {
					background-color: rgba(#fff, 0.3);
        }
      }
    }
  }

  .tasting-summary { 
		padding: 1rem;

		h2 {
			font-size: 1.4rem;
			font-weight: 700;
			margin: 0;
			padding: 0;
		}

		h3 {
			font-size: 1rem;
			font-weight: 700;
			margin: 0;
			padding: 1rem 0 0 0;

			span {
				display: block;
			}
    }

    .results {
			list-style: none;
			margin: 0 auto;
			max-width: 220px;
			padding: 0;
			width: 100%;

			.res--user {
				align-items: center;
				display: flex;
				justify-content: flex-end;
				padding: 0.3rem 0.6rem;

				&:nth-child(even) {
					background-color: rgba(#333, 0.1);
        }

				.stat {
					font-size: 0.8rem;
					font-weight: 700;
					height: 20px;
					line-height: 20px;
					margin: 0 0 0 2px;
					text-align: center;
					width: 20px;

					&.stat--good {
						background-color: #25ba84;
          }

					&.stat--bad {
						background-color: #e74c3c;
          }
        }

				.name {
          font-size: 0.9rem;
					flex-grow: 1;
					margin-left: auto;
        }

				.points {
					font-size: 0.8rem;
					font-weight: 700;
					margin: 0 2p;
        }
      }
    }
  }
}
