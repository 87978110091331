.form-wrapper {
  margin: 0 auto;
  max-width: 360px;
  padding: 100px 0 60px 0;
  width: 90%;

  .Select {
    margin: 0 0 1rem 0;
  
    .Select-control {
      border-radius: 0;
    }
  }
}
