.select-user {
  p {
    margin: 0;
    padding: 0 0 0.5rem 0;
  }

  ul {
    list-style: none;
    margin: 0;
    max-width: 240px;
    padding: 0;
    width: 100%;

    li {
      margin: 0 0 1rem 0;
      position: relative;
      vertical-align: top;
      width: 100%;

      span {
        height: 24px;
        line-height: 24px;
        margin-top: -12px;
        position: absolute;
        right: 0.5rem;
        top: 50%;
      }
    }
  }
}
